.react-calendar {
  width: 350px;
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar--doubleView {
  width: 700px;
}

.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}

.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}

.react-calendar__navigation__prev-button {
  justify-content: center;
}

.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: none;
}

.react-calendar button {
  width: 26.36px;
  border-radius: 25px;

  margin: 0;
  border: 0;
  outline: none;
}

.react-calendar button:enabled:hover {
  cursor: pointer;
}

.react-calendar__navigation {
  display: flex;
  height: 17px;
}

.react-calendar__navigation button {
  /* max-width: 17px; */
  background: none;
  justify-content: center;
}

.react-calendar__month-view {
  margin-top: 13px;
}

.react-calendar__navigation button:disabled {
  background-color: #f0f0f0;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}

.react-calendar__month-view__weekdays__weekday {
  font-size: 11px;
  font-weight: normal;
  padding: 0.5em;
}

.react-calendar__tile--now
  .react-calendar__month-view__weekNumbers
  .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  font-weight: bold;
}

.react-calendar__month-view__days {
  border-top: #dbdbdb solid;
}

.react-calendar__navigation__arrow .react-calendar__navigation__next-button {
  display: none;
}

.react-calendar__month-view__days__day {
  font-size: 12px;
}

.react-calendar__navigation__label__labelText
  .react-calendar__navigation__label__labelText--from {
  font-size: 12px;
}

.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}

.react-calendar .react-calendar__month-view__days__day button {
  border-radius: 25px;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
  justify-items: center;
  align-items: center;
}

.react-calendar__tile {
  max-width: 100%;
  padding: 10px 6.6667px;
  background: none;
  text-align: center;
  line-height: 16px;
}

.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: #e6e6e6;
}

.react-calendar__tile--now {
  background: #dbdbdb;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}

.react-calendar__tile--hasActive {
  background: #76baff;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
}

.react-calendar__tile--active {
  color: white;
}

.react-calendar__tile--active:enabled,
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #334a7c;
}
* .react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #e6e6e6;
}
