.mapboxgl-popup {
  @apply !max-w-none;
}
.mapboxgl-popup-content {
  @apply bg-transparent shadow-none px-0 pt-0 pb-2.5;
}
.mapboxgl-popup-close-button,
.mapboxgl-popup-tip {
  @apply hidden;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 300ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.mapboxgl-ctrl-top-right {
  position: relative;
  z-index: 50;
}
